.secondary-over-background {
  .cmp-button {
    @extend .btn-secondary;
    background-color: $sbs-black;
    color: $sbs-black;
  }


  .cmp-button__icon {
  }
}

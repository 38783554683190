.cmp-button {
  border-style: solid;
  border-width: 0.125em;
  border-radius: 1.56em;
  display: block;
  text-decoration: none;
  height: fit-content;
  margin: 0.5em 0.5em;
  padding: 0.5625em 1em 0.6875em 1em;
  width: fit-content;
}

.cmp-button__text {
  font-weight: 300;
  text-decoration: none;
}


.btn-primary {
  background-color: $sbs-gold;
  border: 0.125em solid $sbs-gold;
  border-radius: 2.563rem;
  color: $color-text-inverted;
  font-family: secondaryFont, serif;
  font-size: 1.25rem;
  padding: 1rem 2.969rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: $screen-lg) {
    font-size: 0.875rem;
    padding: 0.875rem 2.969rem 1rem;
  }

  &:hover {
    background-color: $sbs-gold;
    color: $sbs-white;
  }
  &:focus {
    color: $sbs-white;
    outline: none;
  }
  &:disabled {
    background-color: $color-shade-1;
    border-color: $color-shade-1;
  }
}

.btn-secondary {
  background-color: rgba(0, 0, 0, 0);
  border: 0.125em solid $sbs-gold;
  border-radius: 2.563rem;
  color: $sbs-gold;
  font-family: secondaryFont, serif;
  font-size: 1.25rem;
  padding: 1rem 2.969rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: $screen-lg) {
    font-size: 0.875rem;
    padding: 0.875rem 2.969rem 1rem;
  }
  
  &:hover {
    color: $sbs-white;
  }
  &:focus {
    color: $sbs-white;
    outline: none;
  }
  &:disabled {
    color: $color-shade-1;
    border-color: $color-shade-1;
  }
}

.btn-link {
  border: 0;
  color: $sbs-gold;
  font-size: 1.25rem;
  font-family: $font-family;
  text-decoration: none;

  @media (max-width: $screen-lg) {
    font-size: 0.875rem;
  }

  &:hover,  &:focus {
    color: $sbs-white;
    text-decoration: underline;
  }

  &:disabled {
    background-color: $color-shade-1;
    border-color: $color-shade-1;
  }
}

.offwhite-text {
  .cmp-breadcrumb__item:not(:last-of-type) {
    &:after {
      background-color: $sbs-offwhite;
    }
  }
  .cmp-breadcrumb__item--active {
    color: $sbs-gold
  }

  .cmp-breadcrumb__item-link {
    color: $sbs-offwhite;
    &:hover {
      color: $sbs-gold;
    }
  }
}
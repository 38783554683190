.white-text {
    .cmp-breadcrumb__item:not(:last-of-type) {
      &:after {
        background-color: #FFFFFF;
      }
    }
    .cmp-breadcrumb__item--active {
      color: $sbs-gold
    }
  
    .cmp-breadcrumb__item-link {
      color: #FFFFFF;
      &:hover {
        color: $sbs-gold;
      }
    }
  }
.container {
    &.container--justify-center {
        .cmp-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        .cmp-button {
            margin: auto;
        }

        // .customcarousel {
        //     @media (min-width: $screen-lg) {
        //         width: 100% !important; 
        //     }

        //     .slick-list {
        //         @media (min-width: $screen-lg) {
        //           padding: 0 !important;
        //         }
        //     }

        //     .slick-track {
        //         @media (min-width: $screen-lg) {
        //             margin: 0 auto;
        //         }
        //     }
        // }

    }
}

.cmp-teaser {
  position: relative;
}
.cmp-teaser__image {
  & .cmp-image {
    margin: 0;
  }
  & img {
    display: block;
    // min-height: 57.813rem;
    object-fit: cover;

    // @media (max-width: $screen-md) {
    //   min-height: 38.75rem;
    // }
  }
}
.cmp-teaser__content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  max-width: 68rem;
  transform: translate(-50%, -50%);
  z-index: 10;

  @media (max-width: $screen-md) {
    top: 45%;
    width: 90%;
  }
}
.cmp-teaser__content:only-child {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  .cmp-teaser__pretitle, .cmp-teaser__title, .cmp-teaser__title-link, .cmp-teaser__description  {
    color: $color-text;
  }
}
.cmp-teaser__pretitle {
  color: #ffffff;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.125em;
  letter-spacing: 0.025em;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
}
.cmp-teaser__title {
  margin-top: 0.5625rem;
  margin-bottom: 5.625rem;
  color: #ffffff;
  font-size: 3.5rem;
  font-family: titleFont, serif;
  letter-spacing: 0.525rem;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;

  @media (max-width: $screen-sm) {
    font-size: 2.125rem;
    margin-bottom: 3rem;
    letter-spacing:  0.159rem;
    line-height: 2.5rem;
  }
}
.cmp-teaser__title-link {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
}
.cmp-teaser__description {
  color: #ffffff;
  font-weight: 300;
  margin-bottom: 4.75rem;
  text-align: center;

  @media (max-width: $screen-sm) {
    margin-bottom: 2.5rem;
  }

  p {
    font-size: 1.25rem;
    letter-spacing: 0.033rem;
    line-height: 1.357em;

    @media (max-width: $screen-sm) {
      font-size: 0.875rem;
    }
  }
}
.cmp-teaser__action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $screen-sm) {
    flex-direction: column;
    gap: 0.7rem;
  }
}
.cmp-teaser__action-link {
  margin: 0 auto;
}

.cmp-teaser__action-link:first-child {
  @extend .btn-primary;
}

.cmp-teaser__action-link:not(:first-child) {
  @extend .btn-secondary;
}

.teaser.shrunk img {
  object-fit: scale-down !important;
}

.teaser.cmp-teaser-hero {
  margin: 13rem 0.5em 3.125rem;

  @media (min-width: $screen-sm) {
    margin: 10rem 0 6.25rem;
  }

  @media (min-width: $screen-lg) {
    margin: 15.5rem 0 6.25rem 10rem;
  }


  .cmp-teaser__image {
    @media (min-width: $screen-sm) {
      height: 20.68rem;
      padding-bottom: 0;
      width: 28.12rem;
    }
  }

  .cmp-teaser__description p {
    margin: 0;
  }

}
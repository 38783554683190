.teaser-dark {
  .cmp-teaser__content {
    background-color: $sbs-gold;
    .cmp-teaser__title-link {
      color: $sbs-black;
    }
    .cmp-teaser__action-link {
      border-color: $sbs-black;
    }
  }
}
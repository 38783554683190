.small {
  .cmp-separator {
  }
  .cmp-separator__horizontal-rule {
    height: 0.03em;
    margin-top: 1.5em;
    margin-bottom: 0.9375em;
    width: 95%;
  }
}

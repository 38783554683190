.container {
    &.container--full-width {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        & > .cmp-container {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
         
            & > .aem-Grid {
                margin-left: 0;
                margin-right: 0;
                width: auto;
                & > div[class^='aem-GridColumn'],
                & > div[class*=' aem-GridColumn'] {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}


.aem-Grid.aem-Grid--12 > 
           .aem-GridColumn.aem-GridColumn--offset--default--1.container.container--full-width {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}
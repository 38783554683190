.cmp-accordion {
  margin: 20px 8%;
}

.cmp-accordion__item {
  border-bottom: 1px solid $sbs-gold;
  padding-bottom: 2.5rem;

  @media (min-width: $screen-md) {
    padding-bottom: 4.375rem;
  }
}

.cmp-accordion__button {
  background-color: $color-background;
  border: 0;
  cursor: pointer;
  gap: 1rem;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.875rem 0 0;

  @media (min-width: $screen-md) {
    gap: 5.625rem;
    padding: 3.125rem 1.25rem 0;
  }

  // @media @screen-lg-max

  .cmp-accordion__icon {
    height: 33px;
    &:after {
      width: 33px;
      height: 33px;
      display: inline-block;
      margin: 0;
      vertical-align: -25%;
      background-color: $color-shade-2;
      content: '';
      mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMyIgaGVpZ2h0PSIzMyIgdmlld0JveD0iMCAwIDMzIDMzIj48ZyBpZD0iR3JvdXBfMjE0IiBkYXRhLW5hbWU9Ikdyb3VwIDIxNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3MzAuNSAtNjc0MSkiPjxsaW5lIGlkPSJMaW5lXzEwMyIgZGF0YS1uYW1lPSJMaW5lIDEwMyIgeDI9IjMzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNzMwLjUgNjc1Ny41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjODU3NTRlIiBzdHJva2Utd2lkdGg9IjQiLz48bGluZSBpZD0iTGluZV8xMzMiIGRhdGEtbmFtZT0iTGluZSAxMzMiIHkyPSIzMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTc0NyA2NzQxKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjODU3NTRlIiBzdHJva2Utd2lkdGg9IjQiLz48L2c+PC9zdmc+');
      mask-size: cover;
    }
  }

  &:hover {
    .cmp-accordion__title {}

    .cmp-accordion__icon {
      &:after {
        background-color: $sbs-gold;
      }
    }
  }
}

.cmp-accordion__button--expanded {
  outline: none;

  .cmp-accordion__icon {
    &:after {
      mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgMzMgNCI+PGxpbmUgaWQ9IkxpbmVfMTAyIiBkYXRhLW5hbWU9IkxpbmUgMTAyIiB4Mj0iMzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzg1NzU0ZSIgc3Ryb2tlLXdpZHRoPSI0Ii8+PC9zdmc+');
      width: 33px;
      height: 4px;
      vertical-align: 0;
    }
  }

  &:focus {
    background-color: $sbs-black;
  }
}

.cmp-accordion__header:hover {
  .cmp-accordion__button--expanded {
    .cmp-accordion__icon::after {
      background-color: $sbs-gold;
    }
  }
}

.cmp-accordion__title {
  color: $color-shade-3;
  font: normal normal normal 1.875rem/1.2 Garamond;
  letter-spacing: 7.2px;
  max-width: 85%;

  @media (min-width: $screen-md) {
    font-size: 2.4375rem;
    line-height: 1.1;
    max-width: auto;
  }
}

.cmp-accordion__icon {
  float: right;
}

.cmp-accordion__panel {
  padding-top: 1.5rem;

  @media (min-width: $screen-md) {
    padding: 2.188rem 5.625rem 0 1.25rem;
  }

  .cmp-text {
    margin: 0;
  }

  p {
    font: normal normal normal 0.875rem/1.75 defaultFont;
    letter-spacing: 0.52px;
    margin: 0;

    @media (min-width: $screen-md) {
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
}
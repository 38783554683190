.textAtRight {
  .cmp-teaser {
    align-items: center;
    position: static;
    display: flex;
    flex-direction: column;
    margin: 0;

    @media (min-width: $screen-lg) {
      align-items: stretch;
      flex-direction: row-reverse;
      margin: 0.75em 0;
    }
  }
  .cmp-teaser__image {
    flex: 2;

    @media (max-width: $screen-lg) {
      width: 100%;
    }

    .cmp-image {
      @media (min-width: $screen-lg) {
        height: 100%;
      }
    }

    & img {
      top: 0;
      left: 0;
      display: block;
      min-height: auto;
      width: 100%;
      height: 100%;
      // height: 22em;
      object-fit: cover;
    }
  }
  .cmp-teaser__content {
    position: static;
    top: 0;
    left: 0;
    flex: 1;
    margin: 0;
    padding: 4.688rem 1rem 5rem;
    transform: none;
    width: 100%;


    @media (min-width: $screen-lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem 6.875rem 1rem 8%;
      width: auto;
    }
  }

  .cmp-teaser__description {
    margin-bottom: 4.063rem;

    @media (min-width: $screen-lg) {
      margin-bottom: 5rem;
    }
  }
}

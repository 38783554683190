#homepage-hero {
  padding-top: 160px;
  @media (min-width: $screen-md-max) {
    padding-top: 60px;
  }
  
  .textAtBottom {
    object-fit: contain;
  }
}

.textAtBottom {
  @media (max-width: $screen-md) {
    width: 100% !important;
  }

  .cmp-teaser {
    position: initial;
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
  }
  .cmp-teaser__image {
    margin: 0 auto;
    position: relative;
    padding-bottom: 87.77%;
    overflow: hidden;
    height: 100%;
    // max-height: 11.25rem;
    // max-width: 15.625rem;
    width: 100%;

    // @media (min-width: $screen-lg) {
    //   max-height: 20.688rem;
    //   max-width: 28.125rem;
    // }
    & .cmp-image {
      margin: 0;
    }
    & img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      min-height: auto;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  .cmp-teaser__content {
    position: initial;
    top: 0;
    left: 0;
    padding: 2rem 1rem 0;
    text-align: center;
    transform: none;
  }

  .cmp-teaser__description {
    margin-bottom: 4.375rem;

    @media (min-width: $screen-lg) {
      margin-bottom: 5.5rem;
    }
  }

  .cmp-teaser__action-link:not(:first-child) {
    @extend .btn-link;

    &:before {
      color: $sbs-white;
      content: 'Already a Member? ';
    }
  }
}

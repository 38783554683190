
.teaser.left  {
    .cmp-teaser__image {
        width: 100% !important;
        max-width: 100%;
    }
    @media (min-width: $screen-md-max) {
        max-width: 30%;
        margin-left: 10% !important;

        .cmp-teaser__content {
            width: 90% !important;
        }
        .cmp-teaser__action-container { 
            width: 90% !important;
        }
        .cmp-teaser__action-link:last-child {
            padding: 1rem 0 !important;
        }
        
    }
}

.cmp-carousel {
  margin: 0.5em;
}

.cmp-carousel__content {
  margin-left: 5rem;
  position: relative;
}

.cmp-carousel__item {}

.cmp-carousel__actions {
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 50%;
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: space-between;
  width: 100%;
}

.cmp-carousel__action {
  background-color: #85754E73;
  border-radius: 50%;
  border-width: 0;
  box-shadow: none;
  cursor: pointer;
  height: 5.5rem;
  position: relative;
  width: 5.5rem;
  z-index: 10;

  // margin: 0.125em;
  & .cmp-carousel__action-icon {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      height: 4rem;
      background-color: $white;
      transform: translate(-50%, -50%);
      content: '';
      mask-size: 4rem;
      width: 4rem;
    }
  }

  &:focus {
    outline: none;
  }
}

.cmp-carousel__action--disabled {}

.cmp-carousel__action--previous {
  left: 0rem;

  & .cmp-carousel__action-icon {
    &:before {
      mask-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00ODUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDg1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwMSA5OC42NTcpIHJvdGF0ZSgxODApIi8+PC9nPjwvc3ZnPg==');
    }
  }
}

.cmp-carousel__action--next {
  right: 5rem;

  & .cmp-carousel__action-icon {
    &:before {
      mask-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+');
    }
  }
}

.cmp-carousel__action--pause {
  display: none;
}

.cmp-carousel__action--play {
  display: none;
}

.cmp-carousel__action-icon {}

.cmp-carousel__action-text {
  display: none;
}

.cmp-carousel__indicators {
  display: none;
  bottom: 0;
  height: 20px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.cmp-carousel__indicator {
  width: 2.5rem;
  height: 0.125rem;
  margin: auto 0.125rem 0 0.125rem;
  background-color: $color-shade-1;
  border-radius: 0.125rem;
}

.cmp-carousel__indicator--active {
  background-color: $color-shade-4;
}



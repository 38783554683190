.cmp-list {
    list-style: none;
    font-family: $font-family;
    font-size: $font-size;
    text-transform: uppercase;

    .cmp-list__item { 
        padding-top: 15px;
    }

    .cmp-list__item-link {
        color: $sbs-offwhite;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    
}


.cmp-list__item-title {}
.cmp-list__item-date {}
.cmp-timeline {
    .timeline__inner {
        display: flex;
    }
    .timeline__items {
        margin: 0 auto;
        list-style: none;
        padding: 100px 0;
        position: relative;
        width: 1px;
        background-color: $sbs-gold;
  
      .timeline__item {
        background-color: $sbs-black;
        min-width: 260px;
        margin-bottom: 70px;
        z-index: 10;
        position: relative;
        counter-increment: timeline-item;
        @media (max-width : $screen-md-max) {
            left: 50%;
            transform: translateX(-50%);
        } // @media @screen-lg-max
  
        @media (min-width: $screen-lg) {
          margin-bottom: 30px;
        } // @media @screen-lg
  
        @media (min-width: $screen-xxl) {
          min-width: 400px;
        }
  
        &.timeline__item--even {
          text-align: center;
          @media (min-width: $screen-lg) {
            margin-left: 40px;
            float: left;
            text-align: left;

            .timeline__item-headline:before {
                margin-left: 0;
            }
          } // @media @screen-lg
  
          .timeline__item-image {
            text-align: left;
            .timeline__item-img {
              @media (min-width: $screen-lg) {
                margin-left: 40px;
              } // @media @screen-lg
            }
          }
        } //--even
        &.timeline__item--odd {
          text-align: center;
          @media (min-width: $screen-lg) {
            margin-right: 40px;
            float: right;
            text-align: right;
            .timeline__item-headline:before {
                margin-right: 0;
            }
          } // @media @screen-lg
  
          .timeline__item-inner {
            &.timeline__item-inner--has-image {
              @media (min-width: $screen-lg) {
                flex-direction: row-reverse;
                margin-right: 40px;
              } // @media @screen-lg
            }
          } //timeline__item-inner
  
          .timeline__item-image {
            text-align: right;
            @media (min-width: $screen-lg) {
              margin-right: 40px;
            } // @media @screen-lg
          
          }
        } //--odd
  
        .timeline__item-inner {
          &.timeline__item-inner--has-image {
            display: flex;
            flex-direction: column;
            @media (min-width: $screen-lg) {
              flex-direction: row;
            } // @media @screen-lg
          }
        } //timeline__item-inner
  
        .timeline__item-headline {
          font: normal normal normal 40px/44px Garamond;
          text-transform: uppercase;
          letter-spacing: 7.2px;
          line-height: 1.57;
          color: $sbs-gold;;
          margin: 0 -18% 0 -18%;
          @media (min-width: $screen-lg) {
            margin: 0;
          } 
          &:before {
            content: counter(timeline-item);
            color: $sbs-offwhite;
            display: block;
            font: normal normal normal 30px/40px Topaz;
            width: 72px;
            height: 72px;
            background-color: #313131;
            display: flex;
            justify-content: center;
            letter-spacing: normal;
            align-items: center;
            border-radius: 50%;
            margin: 20px auto;
          }
          
        } //timeline__item-headline
  
        .timeline__item-content {
          flex-basis: 60%;
          max-width: 300px;
          @media (min-width : $screen-xl) {
            max-width: unset
        } //

          p {
            @media (min-width: $screen-lg) { 
              font: normal normal normal 26px/38px Open Sans;
            }
            font: normal normal normal 18px/30px Open Sans;
            
            margin-bottom: 0;
          } //p
        } //timeline__item-content
  
        .timeline__item-image {
          flex-basis: 40%;
          margin-top: 30px;
          margin-bottom: 20px;
          min-width: 200px;
          align-self: center;
          @media (min-width : $screen-xl) { 
            min-width: 400px;
          }
          @media (min-width: $screen-lg) {
            margin-top: 0;
          } // @media @screen-lg
          .timeline__item-img {
            max-width: 100%;
            height: auto;
            display: block;
          }
        }
      } //timeline__item
    } //timeline__items
  }
  
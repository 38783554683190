
//== Font loading

@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: '#{$name}';
        font-style: $style;
        src: url('resources/fonts/#{$file}.ttf') format('truetype');
    }
}

@include fontface(defaultFont, 'OpenSans/OpenSans-Bold', bolder);
@include fontface(defaultFont, 'OpenSans/OpenSans-BoldItalic', bolder, italic);
@include fontface(defaultFont, 'OpenSans/OpenSans-Italic', normal, italic);
@include fontface(defaultFont, 'OpenSans/OpenSans-Light', lighter);
@include fontface(defaultFont, 'OpenSans/OpenSans-LightItalic', lighter, italic);
@include fontface(defaultFont, 'OpenSans/OpenSans-Regular', normal);
@include fontface(defaultFont, 'OpenSans/OpenSans-SemiBold', bold);
@include fontface(defaultFont, 'OpenSans/OpenSans-SemiBoldItalic', bold, italic);
@include fontface(titleFont, 'Garamond/Garamond', normal);

@include fontface(secondaryFont, 'Topaz/TopazBackground', bold, italic);

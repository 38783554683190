.cmp-breadcrumb {
      margin-top: 1rem;
      font-size: 14px;

  @media (min-width: $screen-lg) {
    font-size: 26px;
  }
    }
    .cmp-breadcrumb__list {
      padding-inline-start: 0;
      margin: 0;
    }
    .cmp-breadcrumb__item {
      & a,
      > span {
        padding: 0.5625rem 1rem 0.6875rem 1rem;
      }
    }
    .cmp-breadcrumb__item:not(:last-of-type) {
      &:after {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0;
        vertical-align: -25%;
        background-color: $color-shade-2;
        content: '';
        pointer-events: none;
        mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+');
        mask-size: cover;
      }
    }
    .cmp-breadcrumb__item--active {
      color: $color-text;
      line-height: 1.5rem;
    }
    .cmp-breadcrumb__item-link {
      color: $color-shade-3;
      text-decoration: none;
      border: 0.125rem solid $color-transparent;
      border-radius: 0.25em;
      &:hover {
        color: $color-text;
      }
      &:focus {
        border-color: $color-accent-lighter;
        border-radius: 0.25em;
        outline: none;
      }
    }
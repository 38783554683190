.h1_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: lighter;
    font-size: 2.125em;
    font-family: titleFont, serif;
    line-height: 4.125rem;

    @media (min-width: $screen-md) {
      font-size: 3.25em;  //52px
    }
  }
  .cmp-title__link {
  }
}

.h2_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: 1.875em;
    font-family: titleFont, serif;
    line-height: 3.0625rem;

    @media (min-width: $screen-md) {
      font-size: 2.4375em; //39px
    }
  }
  .cmp-title__link {
  }
}

.h3_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-family: secondaryFont, serif;
    font-weight: normal;
    font-size: 0.875em;   //14px
    line-height: 2.25rem;

    @media (min-width: $screen-md) {
      font-size: 1.25em;  //20px
    }
  }
  .cmp-title__link {
  }
}

.h4_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-family: secondaryFont, serif;
    font-weight: normal;
    font-size: 0.875em;   //14px

    @media (min-width: $screen-md) {
      font-size: 1.125em; //18px
    }
  }
  .cmp-title__link {
  }
}

.h5_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: 0.75em;  //12px

    @media (min-width: $screen-md) {
      font-size: 1em;   //16px
    }
  }
  .cmp-title__link {
  }
}

.h6_style {
  .cmp-title {
  }
  .cmp-title__text {
    color: $color-shade-3;
    font-weight: normal;
    font-size: 0.75em;  //12px
    text-transform: uppercase;

    @media (min-width: $screen-md) {
      font-size: 1em;   //16px
    }
  }
  .cmp-title__link {
  }
}

.cmp-title--homepage-banner {
  width: 100% !important;
  padding: 30px 0;
  margin: 0 !important;

  @media (min-width: $screen-lg) {
    padding: 50px 0;
  }

  .cmp-title {
    margin: 0 !important;
  }

  .cmp-title__text {
    font-family: Garamond;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 7.2px;
    font-weight: normal;
    text-transform: uppercase;

    @media (min-width: $screen-lg) {
      font-size: 52px;
      line-height: 52px;
    }
  }
}

.cmp-title--gold {
  color: $sbs-gold;
}

.cmp-title--black {
  color: $sbs-black;
}

.cmp-title--white {
  color: $sbs-white;
}

//== XF Footer style, used on page template
#main-footer {
  > .aem-Grid {   
    @media (max-width: $screen-md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    

      >.container {
        width: 100%;
        margin: 0;

        #logo-container {
          > .aem-Grid {
           margin-left: 50px;
          }
          .image {
            width: calc(100% - 100px);
          }
          img {
            max-width: 150px;
            margin: auto;
          }

          .list {
            .cmp-list {
              margin: 0;
            }
          }
        }
      }
    }
    .list {
      .cmp-list {
        padding-left: 0;
      }
    }
    #text-links-container {
      margin-top: 50px;
    }
  @media (max-width: $screen-md) {
    #text-links-container {
      margin-left: 50px;
      margin-top: 0;
      > .aem-Grid {
        display: flex;
        flex-direction: column;

        
        .container {
          margin: 0;
          width: fit-content;
        }
      }
    }
  }
}

.text {
  margin: 0;
  .cmp-text {
    color: $sbs-gold;
      p {
        margin: 0;
      }
      a {
        margin: 0;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      
    }
  }

  #copyright-container {
    margin-top: 50px;
  }
}
.cmp-form {

  .text.aem-GridColumn--default--6 {
    @media (max-width: $screen-md) {
      width: 100% !important;
    }
  }

}

.cmp-form-text {
  border-radius: 0;
  display: grid;
  margin: 1.25rem 0.813rem;
  & label {
    margin-bottom: 0.5625rem;
    color: $sbs-gold;
    font-size: 1rem;
  }

  input::placeholder {
    color: rgba(239,228,203, .7);
  }
}
.cmp-form-text__help-block {
}
.cmp-form-text__textarea {
  background-color: $color-background;
  border: 0.125em solid $sbs-gold;
  color: $sbs-offwhite;
  font-size: 1rem;
  height: 10em;
  padding: 1.3rem .5rem;

  &:focus {
    border-color: $color-accent-lighter;
  }
  &:active {
    color: $color-text;
  }
}
.cmp-form-text__text {
  background-color: $color-background;
  border: 0.125em solid $sbs-gold;
  border-style: solid;
  border-width: 0.125em;
  color: $sbs-offwhite;
  font-size: 1rem;
  padding: 1.3rem .5rem;

  &:focus {
    border-color: $color-accent-lighter;
  }
  &:active {
    color: $color-text;
  }


  //&:invalid {
  //  border-color: $error;
  //  color: $color-text;
  //}
  //&:valid {
  //  border-color: $success;
  //  color: $color-text;
  //}
}

.cmp-form-options__field-label {
  .cmp-form-options__field {
    background: $sbs-black;
    border: 1px solid $sbs-gold;
  }

  .cmp-form-options__field-description {
    color: $sbs-gold;
  }
}

.cmp-form-button {
  @extend .btn-primary;

  &:hover {
    background-color: $sbs-gold;
    color: $sbs-white;
  }
}
div.spacer {

  .cmp-spacer--large {
    padding: 100px 0;
  }

  .cmp-spacer--small {
    padding: 40px 0;
  }
  
}
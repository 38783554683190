
#main-footer {
    @media (min-width: $screen-md) {
        padding-top: 75px;
    }

    #logo-container {
        color: green !important;
        @media (min-width: $screen-md) {
            position: relative;
            top: -75px;
            max-width: 300px;
            margin-left: auto;
          }

        .image {
            @media (min-width: $screen-md) {
                max-width: 150px;
            }
        }

        .list {
            @media (min-width: $screen-md) {
                margin-left: 10px;
            }
            .cmp-list__item-link {
                color: $white;
            }
        }  
    }

    #copyright-container {
        margin-bottom: 50px;
    }
}
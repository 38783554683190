.customcarousel {
    min-height: 12.5rem;
    overflow: hidden;
    position: relative;


    &.customcarousel__three-slides {

        .slick-list {

            @media (min-width: $screen-lg) {
                padding: 0 15% 0 0;
            }
        }

        .customcarousel__item {
            display: block !important;

            @media (min-width: $screen-lg) {
                max-width: 34.375rem;
            }
        }

        .teaser {
            .cmp-teaser__image .cmp-image {

                @media (min-width: $screen-md) {
                    height: 38.75rem;
                }
            }
        }
    }

    &.customcarousel__four-slides {
        width: 100% !important;


        .customcarousel__content {
            @media (min-width: 1440px) {
                margin: 0 auto;
            } 
        }

        .slick-list {
            @media (min-width: 1440px) {
                padding: 0 !important;
            }
        }

        .slick-track {

            @media (min-width: 1440px) {
                margin: 0 auto;
            }
        }

        .teaser {
            .cmp-teaser__image .cmp-image {
                @media (min-width: $screen-md) {
                    height: 27.625rem;
                }
            }
        }

    }

    .slick-list {
        padding: 0 30% 0 0;

        @media (min-width: $screen-md) {
            padding: 0 10% 0 0;
        }
    }

    .customcarousel__item {
        display: block !important;
        max-width: 21.563rem;
        // width: 20%;
    }

    .teaser {

        .cmp-teaser__image {
            padding-bottom: 0;

            .cmp-image {
                height: 17.75rem;

                @media (min-width: $screen-md) {
                    height: 20rem;
                }
            }
        }
    }


    .slick-track {
        display: flex;
        gap: 1.25rem;
        margin-left: 1.563rem;
        position: relative;

        @media (max-width: $screen-sm) {
            max-height: 30rem;
        }

        @media (min-width: $screen-lg) {
            gap: 1.875rem;
            margin-left: 4.688rem;
        }

    }

    .slick-custom-arrow {
        background-color: #85754E73;
        border-radius: 50%;
        border-width: 0;
        bottom: 50%;
        box-shadow: none;
        cursor: pointer;
        position: absolute;
        z-index: 10;
        height: 3.125rem;
        width: 3.125rem;


        @media (min-width: $screen-lg) {
            height: 5.5rem;
            width: 5.5rem;

        }

        &:before {
            content: '';
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a,.b{fill:%23F5F4F0;}.a{opacity:0;}</style></defs><g transform="translate(-445 -80)"><rect class="a" width="24" height="24" transform="translate(445 80)"/><path class="b" d="M30.707-3.565a1,1,0,0,1,0-1.414l4.949-4.95-4.949-4.95a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l5.657,5.656a1,1,0,0,1,.293.707,1,1,0,0,1-.293.707L32.121-3.565a1,1,0,0,1-.707.293A1,1,0,0,1,30.707-3.565Z" transform="translate(422.929 101.929)"/></g></svg>');
            background-size: 3.063rem;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            height: 3.063rem;
            width: 3rem;

            @media (min-width: $screen-lg) {
                background-size: 7rem;
                height: 5.5rem;
            }
        }

        &.slick-prev {
            left: 1.875rem;

            @media (min-width: $screen-lg) {
                left: 3%;
            }

            @media (min-width: $screen-xxl) {
                left: 7rem;
            }

            &:before {
                background-position: 0.313rem;
                transform: scaleX(-1);

                @media (min-width: $screen-lg) {
                    background-position: -1.875rem;
                }
            }
        }

        &.slick-next {
            right: 1.875rem;

            @media (min-width: $screen-lg) {
                right: 3%;
            }

            @media (min-width: $screen-xxl) {
                right: 7rem;
            }

            &:before {
                background-position: -0.313rem;

                @media (min-width: $screen-lg) {
                    background-position: -1.875rem;
                }
            }

        }
    }

    .slick-disabled {
        opacity: 0;
        pointer-events: none;
    }

}
//== SBS Specific Variables

$sbs-black: #000000;
$sbs-gold: #85754E;
$sbs-white: #ffffff;
$sbs-offwhite: #EFE4CB;

//== Font

$font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-title: 'Garamond', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-topaz: 'Topaz Background', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size: 16px;
$font-height: 20px;

//== Color
$color-background: $sbs-black;
$color-foreground: $sbs-gold;
$color-link: $sbs-gold;
$color-accent: $sbs-gold;
$color-accent-lighter: $sbs-gold;
$color-accent-darker: rgba(133, 117, 78, 0.43);
$color-shade-1: $sbs-gold;
$color-shade-2: $sbs-gold;
$color-shade-3: $sbs-gold;
$color-shade-4: $sbs-gold;

$white: #F5F4F0;
$error: #d7373f;
$error-darker: #be1e26;
$success: #268e6c;
$success-darker: #0d7553;

$color-text: $sbs-white;
$color-text-inverted: $color-background;
$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

//= Breakpoints
$screen-xs-max: 35.9375em; // 575px

$screen-sm: 36em; // 576px
$screen-sm-max: 47.9375rem; // 767px

$screen-md: 48em; // 768px
$screen-md-max: 61.9375em; //991px

$screen-lg: 62em; // 992px
$screen-lg-max: 74.9375em; // 1199px

$screen-xl: 75em; // 1200px
$screen-xl-max: 87.4375em; // 1399px

$screen-xxl: 93.75em; // 1500px
$screen-xxl-max: 106.1875em; // 1699px

$screen-xxxl: 106.25em; // 1700px;
